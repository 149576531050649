import { Users } from './../entities/users'

const userUploadForm = $('#upload-avatar'),
  usersListBlock = $('#users-list')

$(document).ready(function () {
  //Min user spent time action
  $(document).on('focusout', '.min-spent-value', function () {
    const parentBlock = $(this).closest('.users-block')
    const userId = parentBlock.data('id')
    if (parentBlock.length && userId) {
      const input = parentBlock.find('input.min-spent-value')

      if (input.val()) {
        Users.loadMinSpentTime(userId, input.val(), $(this))
      }
    }
  })

  //Report subscription add receiver1
  $(document).on('change', '#reports-subscription-teammates', function () {
    Users.setReportsSubscriptionByUserID($(this).val(), 1, 1)

    const teammate = $(this).find(':selected').attr('data-teammate'),
      teammateObj = JSON.parse(teammate),
      avatar_src = teammateObj.avatar_src || '/build/img/avatar-new.png',
      userName =
        teammateObj.firstName && teammateObj.lastName
          ? teammateObj.firstName + ' ' + teammateObj.lastName
          : teammateObj.nickname,
      addedTeammateHTML =
        '<div class="table-row">\n' +
        '            <div class="table-column empty-cell">\n' +
        "                <button data-teammate='" +
        teammate +
        '\' data-id="' +
        teammate.id +
        '" type="button" class="user-remove"><i class="fas fa-times"></i></button>\n' +
        '                <a class="employee-avatar" href=""><img src="' +
        avatar_src +
        '" alt=""></a>\n' +
        '                ' +
        userName +
        '                <span class="nickname">(@' +
        teammateObj.nickname +
        ')</span>\n' +
        '            </div>\n' +
        '            <div class="table-column medium-column">' +
        teammateObj.rolename +
        '</div>\n' +
        '            <div class="table-column wide-column"><a href="" class="report-link"> ' +
        teammateObj.email +
        '  </a></div>\n' +
        '        </div>'

    $('#teammate-enabled-report-list').append(addedTeammateHTML)

    $(this).find(':selected').remove()
    //$(this).val(null);
  })

  // unsubscribe teammate from team report
  $(document).on('click', ':button.user-remove', function () {
    Users.setReportsSubscriptionByUserID($(this).data('id'), 1, 0)
    const teammate = $(this).attr('data-teammate'),
      teammateObj = JSON.parse(teammate),
      userName =
        teammateObj.firstName && teammateObj.lastName
          ? teammateObj.firstName + ' ' + teammateObj.lastName
          : teammateObj.nickname
    $('#reports-subscription-teammates').append(
      $('<option></option>')
        .attr('data-teammate', '' + teammate)
        .attr('value', teammateObj.id)
        .text(userName)
    )

    $(this).parent().parent().remove()
  })

  // user subscription for notifications [typeid: 1 = team reports, 2 = task notifications]
  $(document).on('click', '.notifications-subscription', function () {
    const typeId = $(this).data('typeid')
    if (typeId) {
      Users.setReportsSubscription(typeId, $(this).is(':checked'))
    }
  })

  //Avatar uploading
  if (userUploadForm.length) {
    $(document).on('click', '.upload-avatar', function (e) {
      e.preventDefault()

      $('#upload-avatar input[type=file]').trigger('click')
    })

    $(document).on('change', '#upload-avatar input[type=file]', function () {
      Users.loadAvatar(new FormData($('#upload-avatar form')[0]))
    })
  }

  //Add datatable for users list block
  if (usersListBlock.length && jQuery().DataTable) {
    usersListBlock.DataTable()
  }

  if ($('.unread-comments').length) {
    Users.loadUnreadComments()
  }
})
